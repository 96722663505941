/* global WEBPACK_IS_PUBLIC */

import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Img, { useImageSrc } from 'react-app/components/Img';
import Link from 'components/Link';
import Button from 'components/Button';
import Card from 'react-app/components/Card';
import CardActionArea from 'components/CardActionArea';
import CardActions from 'react-app/components/CardActions';
import CardHeader from 'react-app/components/CardHeader';
import CardTitle from 'react-app/components/CardTitle';
import CardContent from 'react-app/components/CardContent';
import HtmlParser from 'react-app/components/HtmlParser';
import ShopButton from 'components/ShopButton';
import { isExternalLink as isExternalLinkFn } from 'lib/helpers';
import { useDeferredUser } from 'context/user';
import { assortmentCard as sizes } from 'config/sizes.cjs';
import * as images from 'config/images';
import { appData } from 'config/data.cjs';
import { themeVariants } from 'styles/theme';

const HeadingProps = {
	color: 'text.primary'
};
const SubheadingProps = {
	color: 'text.secondary'
};

const defaultProps = {
	maxWidth: sizes.maxWidth,
	aspectRatio: sizes.aspectRatio
};

const useClasses = createClasses((theme, {maxWidth = defaultProps.maxWidth}) => ({
	root: {
		margin: 0,
		position: 'relative',
		maxWidth,
		borderRadius: `${theme.shape.borderRadius}px`,
		overflow: 'hidden',
		width: '100%'
	},
	cardActionArea: {
		color: '#000',
		height: 'max-content'
	},
	heading: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		margin: theme.spacing(1),
		color: '#fff',
		borderRadius: theme.spacing(1),
		backgroundColor: theme.palette.primary.dark,
		textTransform: 'uppercase',
		display: 'inline-flex',
		alignItems: 'center',
		padding: theme.spacing(.5, 1),
		fontSize: theme.typography.pxToRem(14),
		justifyContent: 'space-between',
		maxWidth: `calc(100% - ${theme.spacing(2)})`,
		maxHeight: theme.spacing(3.5),
		[theme.breakpoints.down('sm')]: {
			maxHeight: theme.spacing(3),
			maxWidth: `calc(100% - ${theme.spacing(1.5)})`,
			margin: theme.spacing(.75),
			fontSize: theme.typography.pxToRem(12)
		},
		[theme.breakpoints.down('phone')]: {
			maxHeight: theme.spacing(2.75),
			fontSize: theme.typography.pxToRem(11)
		}
	},
	headingText: {
		...theme.mixins.textOverflow,
		lineHeight: 1.5,
		maxHeight: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			lineHeight: 1.35,
			maxHeight: theme.spacing(2)
		},
		[theme.breakpoints.down('phone')]: {
			maxHeight: theme.spacing(1.75)
		}
	},
	startIcon: {
		margin: theme.spacing(0, .5, 0, 0)
	},
	endIcon: {
		margin: theme.spacing(0, -.5, 0, .5)
	},
	image: {},
	cardTitleSubheading: {
		...theme.mixins.lineClamp,
		WebkitLineClamp: 1
	},
	cardTitle: {},
	cardActions: {
		marginTop: 'auto',
		marginBottom: 0,
		borderTop: `solid 1px ${theme.palette.divider}`
	},
	cardHeader: {
		marginTop: theme.spacing(.5),
		marginBottom: theme.spacing(-2)
	},
	cardContent: {
		marginBottom: theme.spacing(1),
		paddingX: theme.config.gutterSpacing,
		'& ul': {
			paddingLeft: theme.spacing(2)
		}
	}
}), {
	name: 'AssortmentCard'
});

function AssortmentCard(props) {
	const [ {userType} ] = useDeferredUser();

	const {
		id,
		title = appData.pages[props.id]?.title,
		subtitle = appData.pages[props.id]?.subtitle,
		href = appData.pages[props.id]?.pathname,
		themeVariantId = appData.pages[props.id]?.options?.themeVariantId,
		headline,
		onClick,
		imageSrc,
		imageId,
		shopLink,
		externalLink,
		userType: userTypeProp,
		html,
		mdSrc,
		maxWidth = defaultProps.maxWidth,
		aspectRatio = defaultProps.aspectRatio,
		...rest
	} = props;

	const classes = useClasses(props);
	const image = images[imageId || `${id}_card`] || images[`${id}_${userType}_card`] || images[id];
	const imgWidth = maxWidth;
	const imgHeight = Math.round(maxWidth / aspectRatio);
	const { width = imgWidth, height = imgHeight, src = image?.src } = useImageSrc(null, image) || {};

	if (WEBPACK_IS_PUBLIC && ((appData.pages[id]?.pathname && !href) || (!appData.pages[id] && !image))) {
		return;
	}

	const isShopLink = shopLink || href?.startsWith?.(appData.contact.shop) || href?.startsWith?.(appData.contact.profiShop);
	const isExternalLink = isExternalLinkFn(href);

	const themeVariant = themeVariants?.[themeVariantId];

	const cardTitle = title ? (
		<Typography
			variant="body1"
			fontWeight="medium"
			component="div"
			className={classes.heading}
			{...(themeVariant && {
				sx: {'&&': {backgroundColor: themeVariant.color}}
			})}
		>
			{isShopLink && (
				<ShopIcon className={classes.startIcon} fontSize="inherit"/>
			)}
			<div className={classes.headingText}>
				{title}
			</div>
			{(isExternalLink || shopLink) ? (
				<OpenInNewIcon className={classes.endIcon} fontSize="inherit"/>
			) : href ? (
				<ArrowIcon className={classes.endIcon} fontSize="inherit"/>
			) : null}
		</Typography>
	) : null;

	if (!html) {
		return (
			<CardActionArea
				{...rest}
				className={clsx(classes.root, classes.cardActionArea)}
				component={href || shopLink ? Link : Stack}
				href={href || shopLink}
			>
				<Img
					key={id}
					{...image}
					width={width}
					height={height}
					src={src}
					fullWidth
					showPlaceholder
					rounded
					className={classes.image}
				/>
				{cardTitle}
			</CardActionArea>
		);
	}

	return (
		<Card
			{...rest}
			className={classes.root}
		>
			<CardActionArea
				href={onClick ? undefined : href}
				component={href && !onClick ? Link : Stack}
				className={classes.cardActionArea}
				display="block"
				position="relative"
			>
				<Img
					key={id}
					{...image}
					width={width}
					height={height}
					src={src}
					fullWidth
					showPlaceholder
					rounded
					className={classes.image}
				/>
				{cardTitle}
			</CardActionArea>
			{headline && (
				<CardHeader dense className={classes.cardHeader}>
					<CardTitle
						dense
						subtitle={subtitle}
						classes={{
							root: classes.cardTitle,
							subheading: classes.cardTitleSubheading
						}}
						HeadingProps={HeadingProps}
						SubheadingProps={SubheadingProps}
					>
						{headline}
					</CardTitle>
				</CardHeader>
			)}
			{html && (
				<CardContent className={classes.cardContent}>
					<HtmlParser html={html}/>
				</CardContent>
			)}
			{(shopLink || externalLink) && (
				<CardActions dense className={classes.cardActions}>
					{shopLink && (
						<ShopButton
							href={shopLink}
							variant="text"
						/>
					)}
					{externalLink && (
						<Button
							href={externalLink}
							component={Link}
						>
							mehr erfahren
						</Button>
					)}
				</CardActions>
			)}
		</Card>
	);
}

AssortmentCard.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string
};

export default React.memo(AssortmentCard);
